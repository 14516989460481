import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import ContactUsImage from "../assets/contact-image.png";
import { Link } from "react-router-dom";
import EnquiryForm from "../components/EnquiryForm";
import { OrderProvider } from "../storeManagement/OrderContext";
export default function Enquiry() {
  return (
    <section className="contact-us-page">
      <Container fluid>
        <Row>
          <Col md="12" lg="12" sm="12" xs="12">
            <div className="back-icon">
            <Link to="/"><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
            </div>
          </Col>
        </Row>
        </Container>
        <Container fluid className="padding-left-none">
        <Row className="mt-5">
          <Col md="7" lg="7" sm="7" xs="12" className="about-us-bg">
            <div className="">
              <img
                src={ContactUsImage}
                alt="ContactUsImage"
                title="ContactUsImage"
                className="w-100 contact-image"
              />
            </div>
          </Col>
          <Col md="5" lg="5" sm="5" xs="12">
            <h3>Enquiry Form</h3>
            <OrderProvider>
               <EnquiryForm />
            </OrderProvider>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
