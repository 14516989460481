import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import OrderConfirmImage from '../assets/Order-Page-image.png'
export default function OrderConfirmation() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get('id');
    const status = queryParams.get('status');
    const paymentId = queryParams.get('paymentId');
    return(
        <section className="gallery-page order-confirm-page">
            <Container fluid>
                {
                    window.innerWidth>768?
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <div className="back-icon">
                            <Link to='/'><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
                            </div>
                        </Col>
                    </Row>
                : null
                }
                <Row className="mt-5">
                {
                    window.innerWidth>768?
                    <Col md="5" lg="5" sm="5" xs="12">
                        <div className="order-confirm-image">
                            <img src={OrderConfirmImage} alt="OrderConfirmImage" title="OrderConfirmImage" className="w-100" />
                        </div>
                    </Col>
                    : null}
                    <Col md="7" lg="7" sm="7" xs="12">
                        <div className="order-confirm-content">
                            <div className="mt-3">
                                {
                                    status?
                                    status === 'PAID'?
                                        <>
                                                <h1>Payment successfully</h1>
                                                <h6>Thanks for placing order in Aqua viva. </h6>
                                                <h6>your order id: <span>{queryValue}</span> </h6>
                                                <h6>Payment id:<span>{paymentId}</span></h6>
                                                <h6>Payment Status: <span>{status}</span></h6>
                                                <h6>Our Delivery team will approach you soon.</h6>
                                        </>
                                    : 
                                    <>
                                        <h1>Payment Failure</h1>
                                        <h6>Thanks for placing order in Aqua viva. </h6>
                                        <h6>your order id: <span>{queryValue}</span> </h6>
                                        <h6>Payment Status: <span className="note-failure">{status}</span></h6>
                                        <h6>Our Delivery team will approach you soon and then you can pay the amount.</h6>
                                    </>
                                    : 
                                    <>
                                    <h1>Order Confirmation</h1>
                            
                                    <h6>Thanks for placing order in Aqua viva.</h6>
                                        <h6>your order id: <span>{queryValue}</span> </h6>
                                        <h6>Our Delivery team will approach you soon.</h6>
                                    </>
                                    
                                }
                                <div className="mt-3">
                                    <Link to='/' className="btn btn-primary">Go Back</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}