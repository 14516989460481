export default function ContactDataController(data) {
    return new Promise((resolve,reject)=>{
        return fetch(`${process.env.PUBLIC_URL}/apis/contact-info`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber
            })
        }).then(response => {
            if (!response.ok) {
              // If response is not OK, reject the Promise with an error
              throw new Error('Network response was not ok');
            }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            console.log(jsonData);
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}