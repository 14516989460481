import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from '../assets/arrow-prev.svg'
import AboutUspage from '../assets/about-image.png'
import Features from "../components/Features";
import { Link } from "react-router-dom";
export default function About() {
    return(
        <>
           <section className="about-page">
              <Container fluid className="padding-right-none">
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <div className="back-icon">
                            <Link to='/'><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="7" lg="7" sm="7" xs="12">
                        <h3>Welcome to DJ&C Beverages</h3>
                        <p>A forward-thinking startup revolutionizing the packaged drinking water industry with our flagship brand, Aqua Viva. Our commitment to excellence is rooted in compliance with BIS regulations, ensuring every drop we produce is of the highest purity and quality.</p>
                        <p>Nestled within the serene premises of Leonia Resorts, our cutting-edge water plant is the heart of our operations. It's here that innovation meets efficiency: our facility boasts a fully automated 400 BPH (Bottles Per Hour) RFC (Rinse Fill Cap) system in the 20-liter segment, a comprehensive 7-stage filtration system, and a 6LPH (Liters Per Hour) capacity RO (Reverse Osmosis) unit. Our onsite laboratory is equipped with the latest technology to guarantee that our water remains unadulterated and safe for consumption.</p>
                        <p>Location is key to our success. Positioned strategically, we ensure prompt delivery of our products, overcoming logistical challenges with ease. Our end-to-end distribution process, from production straight to our customers' hands, is seamless and efficient.</p>
                        <p>We invite you to experience the essence of Aqua Viva. A visit to our plant will immerse you in the intricacies of our production process, showcase the advanced technology we utilize, and demonstrate our unwavering dedication to quality. It’s an opportunity to witness our commitment to innovation and to maintaining industry-leading standards.</p>
                        <p>Our spacious warehouse, optimized for can storage, operates under a FIFO (First In, First Out) system, preserving the freshness and quality of our product. Our promise to provide on-time service and customer satisfaction distinguishes us in the marketplace.</p>
                        <p className="mb-5">A tour of our facilities is more than just a visit; it's a chance for us to address any questions or concerns you may have, and to showcase our capabilities. We’re eager to welcome you and discuss how Aqua Viva can make a difference. Please share your availability, and we will arrange everything for your visit.</p>
                    </Col>
                    <Col md="5" lg="5" sm="5" xs="12" className="about-us-bg">
                        <div className="about-us-page-image desktop-none">
                            <img src={AboutUspage} alt="AboutUspage" title="AboutUspage" className="w-100" />
                        </div>
                    </Col>
                </Row>
              </Container>
           </section>
           <section className="about-us-block-content">
            <Features />
           </section>
        </>
    )
}