export const GalleryCarouselData = [{
    imgSrc: 'gallery/gallery-1.JPG',
    title: 'gallery 1'
},
{
    imgSrc: 'gallery/gallery-2.JPG',
    title: 'gallery 2'
},
{
    imgSrc: 'gallery/gallery-3.JPG',
    title: 'gallery 3'
},
{
    imgSrc: 'gallery/gallery-4.JPG',
    title: 'gallery 4'
},
{
    imgSrc: 'gallery/gallery-5.JPG',
    title: 'gallery 5'
},
{
    imgSrc: 'gallery/gallery-6.JPG',
    title: 'gallery 6'
},
{
    imgSrc: 'gallery/gallery-14.JPG',
    title: 'gallery 14'
},
{
    imgSrc: 'gallery/gallery-15.JPG',
    title: 'gallery 15'
},
{
    imgSrc: 'gallery/gallery-16.JPG',
    title: 'gallery 16'
},
{
    imgSrc: 'gallery/gallery-17.JPG',
    title: 'gallery 17'
},
{
    imgSrc: 'gallery/gallery-18.JPG',
    title: 'gallery 18'
},
{
    imgSrc: 'gallery/gallery-19.JPG',
    title: 'gallery 19'
},
{
    imgSrc: 'gallery/gallery-20.JPG',
    title: 'gallery 20'
},
{
    imgSrc: 'gallery/gallery-23.JPG',
    title: 'gallery 23'
},
{
    imgSrc: 'gallery/gallery-25.JPG',
    title: 'gallery 25'
},
{
    imgSrc: 'gallery/gallery-26.JPG',
    title: 'gallery 26'
}]