import { GET_PROPERTIES_LIST_API_URL, PROPERTY_DETAILS } from "../lib/BaseConstants";

export const getPropertyList = () => {
    return new Promise((resolve,reject)=>{
      return fetch(`${GET_PROPERTIES_LIST_API_URL}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
      }).then(async response => {
          console.log(response);
          // Extract cookies from response headers
          if (!response.ok) {
              console.log(response);
            // If response is not OK, reject the Promise with an error
            return response.json().then(errorData => {
              throw errorData;
            });
          }
  
          // Parse JSON response
          return response.json();
        })
        .then(jsonData => {
          // Resolve the Promise with JSON data
          resolve(jsonData);
        }).catch((err)=>{
          reject(err)
      })
  })
}

export const getPropertyDetails = (id) => {
  return new Promise((resolve,reject)=>{
    return fetch(`${PROPERTY_DETAILS}/${id}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
    }).then(async response => {
        console.log(response);
        // Extract cookies from response headers
        if (!response.ok) {
            console.log(response);
          // If response is not OK, reject the Promise with an error
          return response.json().then(errorData => {
            throw errorData;
          });
        }

        // Parse JSON response
        return response.json();
      })
      .then(jsonData => {
        // Resolve the Promise with JSON data
        resolve(jsonData);
      }).catch((err)=>{
        reject(err)
    })
})
}
