import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import { GalleryData } from "./GalleryData";
import { Link } from "react-router-dom";
import LazyImage from "../components/LazyImage";
export default function Gallery() {
    const data = GalleryData
    return(
        <section className="gallery-page">
            <Container fluid>
        <Row>
          <Col md="12" lg="12" sm="12" xs="12">
            <div className="back-icon">
            <Link to="/"><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
            </div>
          </Col>
        </Row>
        <Row>
            {
                data && data.length>0 ?
                data.map((response)=>{
                    return(<Col md="3" lg="3" sm="3" xs="12">
                        <div className="gallery-images">
                            <LazyImage src={process.env.PUBLIC_URL+'/'+response.imgSrc} alt={response.title} />
                        </div>
                    </Col>
                    )
                })
                : null
            }
        </Row>
        </Container>
        </section>
    )
}