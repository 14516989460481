/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
export default function Footer() {
    const location = useLocation();
    const { pathname } = location;
    return(
        <>
        {
            window.innerWidth < 768 && pathname==='/shop' ?
            null:
            <section className="footer">
            <Container fluid>
                <Row>
                    <Col md="4" lg="4" sm="4" xs="12">
                    <div className="footer-content">
                            <h1>Aqua Viva</h1>
                            <p>Address is Sy No 399, Bommaraspet Village, <br/>
                            Shamirpet mandal, Medchal Malkagiri dist <br/>
                            500078 </p>
                            <p className="strong">Mail: contactus@aquavivawater.in</p>
                        </div>
                    </Col>
                    <Col md="2" lg="2" sm="2" xs="12">
                        <div className="footer-content">
                            <h5>Important Links</h5>
                            <Link to={process.env.PUBLIC_URL+'/about'}>About us</Link>
                            <HashLink to={process.env.PUBLIC_URL+'/#product-services'}>Products/Services</HashLink>
                            <Link to={process.env.PUBLIC_URL+'/gallery'}>Gallery</Link>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="footer-content mt-3">
                            <p className="size-22">Welcome to DJC Beverages, a forward-thinking startup revolutionizing the packaged drinking water industry with our flagship brand, Aqua Viva. Our commitment to excellence is rooted in compliance with BIS regulations, ensuring every drop we produce is of the highest purity and quality.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        }
        <section className={pathname==='/shop'?'copyright fixed-mobile-footer':'copyright'}>
            <Container fluid>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h6><span>© 2021 dias.works</span><span><Link to={process.env.PUBLIC_URL+'/terms-conditions'}>Terms & Conditions</Link></span><span><Link to={process.env.PUBLIC_URL+'/privacy-policy'}>Privacy Policy</Link></span><span><Link to={process.env.PUBLIC_URL+'/shipping-policy'}>Shipping Policy</Link></span><span><Link to={process.env.PUBLIC_URL+'/refund-policy'}>Refund Policy</Link></span><span>Sitemap</span></h6>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
        
    )
}