import {jwtDecode} from 'jwt-decode';

export async function parseCookiesFromHeaders(responseHeaders) {
    const list = {};
    const cookieHeader = responseHeaders.headers.get("Set-Cookie");
    console.log(responseHeaders.headers.get('set-cookie'));
    
    if (!cookieHeader) return list;

    cookieHeader.split(';').forEach(function (cookie) {
        let [name, ...rest] = cookie.split(`=`);
        name = name?.trim();
        if (!name) return;
        const value = rest.join(`=`).trim();
        if (!value) return;
        list[name] = decodeURIComponent(value);
    });

    return list;
}



export const JwtTokenDecode = (data) => {
    try {
        const decoded = jwtDecode(data);
        if (decoded.exp < Date.now() / 1000) {
          // Token is expired
          return null;
        }
        return decoded;
      } catch (error) {
        return null;
      }
};  
