import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button"
import BannerRightImage from '../assets/15-Pet-Water-Bottle.png'
import Features from "../components/Features";
import FullyAutomated from '../assets/cloud-pak-manta-automated-data-lineage.svg'
import RinseFillCapSystem from '../assets/capslock-rounded.svg'
import LPHCapacity from '../assets/water-damage.svg'
import ProductImage from '../assets/25lt-bottle.png'
import ReflectedImage from '../assets/25l-water-bottle.png'
import Drinkingwater from '../assets/Drinkingwater.png'
import SmallWaterbottle from '../assets/Small-Waterbottle.png'
import WaterBottlePour from '../assets/water-bottle-pour.png'
import CircleImage from '../assets/arc.png'
import LogoWhite from '../assets/logo-white.png'
import { Form } from "react-bootstrap";
import GalleryScrollingItems from "../components/GalleryCarousel";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import ContactDataController from "../controllers/ContactDataController";
export default function HomPage() {
    const [contact,setContact] = useState({name:'',email:'',mobileNumber:''});
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
    const handleMobileNumberChange = (event) => {
    const input = event.target.value;
    // Regular expression to match a 10-digit mobile number starting with 7, 8, or 9
    const mobileNumberRegex = /^[789]\d{9}$/;
    setContact({
        ...contact,
        mobileNumber: input
    });
    setIsValidMobileNumber(mobileNumberRegex.test(input));
  };
  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            event.preventDefault();
            ContactDataController(contact).then((res)=>{
                console.log(res);
                if(res.success===1) {
                   alert('Thanks for contacting us.our team will get back to you soon.');
                   formRef.current.reset();
                    setContact({ name: '', email: '', mobileNumber: '' });
                    setIsValidMobileNumber(true);
                    setValidated(false);
                } else {
                    alert(res.message);
                }
            }).catch((err)=>{
                alert('Something went wrong please try again');
            })
        }
        setValidated(true);
    };
    return(
        <>
            <section className="banner-section">
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={6} sm={6} md={6}>
                            <div className="banner-content">
                                <div className="section-title">
                                    <h3>CLEAN DRINKING WATER</h3>
                                    <h1>Aqua viva</h1>
                                </div>
                                <p>Explore the power of clean drinking water with Aqua Viva. Join us in our mission to provide sustainable solutions for a healthier, happier world. </p>
                                <div className="mt-3">
                                    <Link to={process.env.PUBLIC_URL+'/contact-us'} className="btn btn-primary">Contact Now</Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} sm={6} md={6}>
                            <div className="banner-right-image">
                                <img src={BannerRightImage} alt="15-Pet-Water-Bottle" title="15-Pet-Water-Bottle" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-us-top">
                <Container>
                    <Row className="relative-position-index">
                        <Col md="12" lg="12" sm="12" xs="12">
                            <p className="text-center">Welcome to DJC Beverages, a forward-thinking startup revolutionizing the packaged drinking water industry with our flagship brand, Aqua Viva. Our commitment to excellence is rooted in compliance with BIS regulations, ensuring every drop we produce is of the highest purity and quality.</p>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        <Features />
                    </div>
                </Container>
            </section>
            <section className="about-us">
                <Container fluid>
                    <Row>
                        <Col md="5" lg="5" sm="5" xs="12" className="aboutus-image">
                        </Col>
                        <Col md="7" lg="7" sm="7" xs="12">
                            <div className="about-us-content">
                                <div className="section-title">
                                    <h3>About</h3>
                                    <h1>Aqua viva</h1>
                                </div>
                                <p>Nestled within the serene premises of Leonia Resorts, our cutting-edge water plant is the heart of our operations. It's here that innovation meets efficiency: our facility boasts a fully automated 400 BPH (Bottles Per Hour) RFC (Rinse Fill Cap) system in the 20-liter segment, a comprehensive 7-stage filtration system, and a 6LPH (Liters Per Hour) capacity RO (Reverse Osmosis) unit. Our onsite laboratory is equipped with the latest technology to guarantee that our water remains unadulterated and safe for consumption.</p>
                                <Row className="mt-3 aboutus-features-icons">
                                    <Col md="4" lg="4" sm="4" xs="6">
                                        <div className="about-aquaviva-features">
                                            <div className="about-icon">
                                                <img src={FullyAutomated} alt="Fully Automated" title="Fully Automated" />
                                            </div>
                                            <h5>Fully Automated</h5>
                                        </div>
                                    </Col>
                                    <Col md="4" lg="4" sm="4" xs="6">
                                        <div className="about-aquaviva-features">
                                            <div className="about-icon">
                                                <img src={RinseFillCapSystem} alt="Rinse Fill Cap System" title="Rinse Fill Cap System" />
                                            </div>
                                            <h5>Rinse Fill Cap System</h5>
                                        </div>
                                    </Col>
                                    <Col md="4" lg="4" sm="4" xs="6">
                                        <div className="about-aquaviva-features">
                                            <div className="about-icon">
                                                <img src={LPHCapacity} alt="6LPH Capacity" title="6LPH Capacity" />
                                            </div>
                                            <h5>6LPH Capacity</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <Link to={process.env.PUBLIC_URL+'/about'} className="btn btn-primary">Learn More</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="relative-positon" id="product-services">
                <section className="products">
                    <Container fluid>
                        <Row>
                            <Col md="8" lg="8" sm="8" xs="12">
                                <div className="products-content">
                                    <div className="section-title">
                                        <h3>Our Products</h3>
                                    </div>
                                    <p>Aqua Viva's primary product is high-quality, packaged drinking water. This is <br/>
                                    made available primarily in the 20-liter segment, catering to both individual and <br/>
                                    corporate clients who prioritize health and quality. </p>
                                    <div className="mt-3">
                                        <Link to={process.env.PUBLIC_URL+'/shop'} className="btn btn-primary">Order Now</Link>
                                    </div>
                                    <div className="note-important">
                                        <p>Starting Price from Rs. 85/-</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" lg="4" sm="4" xs="12">
                                <div className="products-image">
                                    <div className="reflected-image">
                                        <img src={ReflectedImage} alt="ReflectedImage" title="ReflectedImage" />
                                    </div>
                                    <img src={ProductImage} alt="ProductImage" title="ProductImage" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="why-djc-beverajes position-relative">
                    <Container fluid>
                        <Row>
                            <Col md="12" lg="12" sm="12" xs="12">
                                <div className="section-title">
                                    <h3>Why DJC Beverages?</h3>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md="3" lg="3" sm="3" xs="12">
                                <img src={Drinkingwater} alt="Drinkingwater" title="Drinkingwater" className="w-100 z-index-1" />
                            </Col>
                            <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                                <div className="drinking-watercontent">
                                    <h3>Commitment to Quality and Safety:</h3>
                                    <p>Adherence to BIS regulations and employing advanced purification technologies.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="products-adjust-top justify-content-end">
                            <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                                <div className="drinking-watercontent text-end padding-left">
                                    <h3>Innovation in Production:</h3>
                                    <p>A fully automated 400 BPH RFC system in the 20-liter segment for efficient production.</p>
                                </div>
                            </Col>
                            <Col md="4" lg="4" sm="4" xs="12">
                                <img src={SmallWaterbottle} alt="SmallWaterbottle" title="SmallWaterbottle" className="w-100" />
                            </Col>
                            
                        </Row>
                        <Row className="products-adjust-top circle-cover">
                            <Col md="5" lg="5" sm="5" xs="12">
                                <div className="circle-image-arc">
                                    <img src={CircleImage} alt="CircleImage" title="CircleImage" />
                                </div>
                                <img src={WaterBottlePour} alt="WaterBottlePour" title="WaterBottlePour" className="w-90" />
                            </Col>
                            <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                                <div className="drinking-watercontent">
                                    <h3>Customer-Centric Services:</h3>
                                    <p>Focus on customer satisfaction through on-time service delivery and addressing client queries and concerns through personalized plant visits. </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="gallery">
                    <Container fluid>
                        <Row>
                            <Col md="12" lg="12" sm="12" xs="12">
                                <div className="section-title">
                                    <h3>Gallery</h3>
                                </div> 
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md="7" lg="7" sm="7" xs="12">
                                <GalleryScrollingItems />
                            </Col>
                            <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                                <div className="gallery-content">
                                    <h3>Pictures of Plant</h3>
                                    <ul>
                                        <li><span>Facility includes a 6LPH capacity reverse osmosis</span></li>
                                        <li><span>A Clean in Place (CIP) system ensures equipment is technically cleaned and sanitized before and after production.</span></li>
                                        <li><span>Raw materials are sourced exclusively from vendors certified by the FSSAI.</span></li>
                                        <li><span>Critical Control Points (CCPs), Operational Prerequisite Programs (OPRPs), and Prerequisite Programs (PRPs) are closely monitored.</span></li>
                                    </ul>
                                    <div className="mt-3">
                                        <Link to={process.env.PUBLIC_URL+'/gallery'} className="btn btn-primary">View All</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </section>
            <section className="footer-contact">
                <Container>
                    <Row>
                        <Col md="7" lg="7" sm="7" xs="12">
                            <div className="footer-contact-logo">
                                <img src={LogoWhite} alt="LogoWhite" title="LogoWhite" />
                            </div>
                        </Col>
                        <Col md="5" lg="5" sm="5" xs="12">
                            <div className="section-title">
                                <h3 className="text-white">Contact Us</h3>
                            </div>
                            <Form ref={formRef} className="mt-5" noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group controlId="name" className="form-group">
                                    <Form.Control type="text" placeholder="Name" onChange={e=>setContact({...contact,name:e.target.value})} value={contact.name} required></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter name...
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email" className="form-group">
                                    <Form.Control type="email" placeholder="Email Id" onChange={e=>setContact({...contact,email:e.target.value})} value={contact.email} required></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter email...
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="mobileNumber" className="form-group">
                                    <Form.Control type="number" placeholder="Mobile Number"  isInvalid={!isValidMobileNumber} onChange={handleMobileNumberChange} value={contact.mobileNumber} required></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter mobile number...
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="mt-5">
                                    <Button type="submit" className="mt-3">Submit</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}