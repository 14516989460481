import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GalleryCarouselData } from "./GalleryCarouselData";
const GalleryScrollingItems = () => {
    const settings = {
        dots: false,
    infinite: true,
    slidesToShow: 1, // Show two items at a time
    slidesToScroll: 1,
    speed: 10000,
    autoplay: true,
    autoplaySpeed: 10000,
    rows: 2,
    slidesPerRow: 2,
    swipeToSlide: true, // Enable dragging
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1, // Change to one row for smaller screens
          slidesPerRow: 1
        },
      },
    ],
      };
    const items = GalleryCarouselData;
    return (
        <div className="slider-container">
            <Slider {...settings}>
        {items.map((item, index) => (

            <div key={index} style={{ width: 600 }}>

            <img src={process.env.PUBLIC_URL+'/'+item.imgSrc} alt={item.title} style={{ width: '100%', height: 'auto' }} />

            </div>

            ))}
          </Slider>
        </div>
      );
};

export default GalleryScrollingItems;
