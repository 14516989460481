import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import { Link } from "react-router-dom";
export default function ShippingPolicy() {
  return (
    <section className="gallery-page">
      <Container fluid>
        <Row>
          <Col md="12" lg="12" sm="12" xs="12">
            <div className="back-icon">
              <Link to="/">
                <img src={PrevArrow} alt="PrevArrow" title="PrevArrow" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col
            md="12"
            lg="12"
            sm="12"
            xs="12"
            className="drinking-watercontent privacy-policy"
          >
            <h1>Terms of service</h1>
            <div className="policy_body mt-3">
              <h2>Shipping: </h2>
              <ul>
                <li>
                  <strong>Shipping Time:</strong> Orders are usually processed
                  and shipped within 3-4 days. Please note personalized items
                  will take longer to process. If your order has both
                  personalized and non-personalized items, the order will be
                  split, and the non-personalized items will be delivered
                  beforehand.
                </li>
                <li>
                  <strong>Shipping Charges:</strong> We offer free shipping on
                  all orders in India. Please note that we do not offer free
                  shipping on international orders and returns.
                </li>
                <li>
                  <strong>Tracking:</strong> You will receive tracking details
                  over WhatsApp, once the order is shipped.{" "}
                </li>
                <li>
                  In case you’re ordering other items along with personalized,
                  your order might arrive in parts.{" "}
                </li>
              </ul>
              <h2>
                Returns : Please go through refund-policy for detailed{" "}
                <Link to={process.env.PUBLIC_URL + "/return-policy"}>
                  Return Policy
                </Link>{" "}
              </h2>
              <h2>
                For more detailed TnCs, please refer to our Terms of Service
                page:{" "}
                <Link to={process.env.PUBLIC_URL + "/terms-conditions"}>
                  Terms and conditons
                </Link>
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
