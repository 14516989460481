import { useEffect, useRef, useState } from "react";
import { Button, Form,Row,Col } from "react-bootstrap";
import {useOrderContext } from '../storeManagement/OrderContext'
import { SendOtp, UpdateAddress, VerifyOtp } from "../services/UserService";
import { USER_JWT_TOKEN, USER_LOGIN_DATA, USER_MOBILE_VERIFIED } from "../lib/BaseConstants";
import Notifications from "./Notification";
import SpinnerGrow from "./SpinnerGrow";
import { JwtTokenDecode } from "../lib/Utilts";
import { useNavigate } from "react-router-dom";
export default function EnquiryForm() {
    const { order, setOrder } = useOrderContext();
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
    const handleMobileNumberChange = (event) => {
        const input = event.target.value;
        // Regular expression to match a 10-digit mobile number starting with 7, 8, or 9
        const mobileNumberRegex = /^[789]\d{9}$/;
        setOrder({
        ...order,
        mobileNumber: input,
        });
        setIsValidMobileNumber(mobileNumberRegex.test(input));
    };
    const [validated, setValidated] = useState(false);
    const [orderFormValidated,setOrderValidated] = useState(false);
    const [otpFormValidated, setOtpValidated] = useState(false);
    const [timer, setTimer] = useState(60);

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timer]);
    const sentOtp = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        } else {
            event.preventDefault();
            if (isValidMobileNumber) {
                setLoading(true);
                // send an otp to the user
                SendOtp(order.mobileNumber).then((res)=>{
                    setShowAlert(true);
                    setMessage('Otp sent to your mobile number');
                    setOrder({
                        ...order,
                        isOtpSent: true
                    });
                    setValidated(false);
                    setTimer(60);
                }).catch((err)=>{
                    console.log(err);
                    setShowAlert(true);
                    setMessage(err.data?err.data:err.message?err.message:err.error?err.error:'Otp not sent to your mobile number');
                    setAlertVariant('danger');
                }).finally(()=>{
                    setLoading(false);
                })
                
            }
        }
        setValidated(true);
    }
    const verifyOtp = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        } else {
        event.preventDefault();
        setLoading(true);
        const payload = {
            mobileNumber: order.mobileNumber,
            otp: order.otp
        };
        await VerifyOtp(payload).then((res)=>{
            setShowAlert(true);
            window.localStorage.setItem(USER_LOGIN_DATA,JSON.stringify(res.user));
            window.localStorage.setItem(USER_MOBILE_VERIFIED,'true');
            window.localStorage.setItem(USER_JWT_TOKEN,res.jwt);
           setOrder({
                ...order,
                isOtpVerified: true,
                isOtpSent: true,
                name: res.user.name,
                blockName: res.user?res.user.addresses?res.user.addresses.apartmentBlock?res.user.addresses.apartmentBlock:'':'':'',
                flat:res.user?res.user.addresses?res.user.addresses.appartmentFlat?res.user.addresses.appartmentFlat:'':'':''
            });
            setValidated(false);
            setAlertVariant('success');
            setMessage('Otp is verified successfully');
        }).catch((err)=>{
            setShowAlert(true);
            setMessage(err.data?err.data:err.message?err.message:err.error?err.error:'Otp not sent to your mobile number');
            setAlertVariant('danger');
        }).finally(()=>{
            setLoading(false);
        })
    }
       setOtpValidated(true);
    }

    // update the address if the data is added or updated
    const changeAddress = async (tokenData,order) => {
        const userInfo = tokenData.data.userEntity;
        if(Object.keys(userInfo.addresses).length !== 0) {
            if(userInfo.addresses.apartmentBlock !== order.blockName || userInfo.addresses.appartmentFlat!==order.flat) {
                const data = {
                    name: order.name,
                    apartmentBlock: order.blockName,
                    appartmentFlat: order.flat
                };
                setLoading(true);
                await UpdateAddress(data).then((res)=>{
                    window.localStorage.setItem(USER_LOGIN_DATA,JSON.stringify(res.user));
                    window.localStorage.setItem(USER_MOBILE_VERIFIED,'true');
                    window.localStorage.setItem(USER_JWT_TOKEN,res.jwt);
                }).catch((err)=>{
                    setShowAlert(true);
                    setMessage(err.data?err.data:err.message?err.message:err.error?err.error:'Update Address is not found');
                    setAlertVariant('danger');
                }).finally(()=>{
                    setLoading(false);
                })
            }
        } else {
            const data = {
                name: order.name,
                apartmentBlock: order.blockName,
                appartmentFlat: order.flat
            };
            setLoading(true);
            await UpdateAddress(data).then((res)=>{
            }).catch((err)=>{
                setShowAlert(true);
                setMessage(err.data?err.data:err.message?err.message:err.error?err.error:'Update Address is not found');
                setAlertVariant('danger');
            }).finally(()=>{
                setLoading(false);
            })
    };
}

const  orderPlaced = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        } else {
        event.preventDefault();
        const token = localStorage.getItem(USER_JWT_TOKEN);
        const tokenData = JwtTokenDecode(token);
        if(tokenData) {
            await changeAddress(tokenData,order);
            history(`/shop`);
        } else {
            setMessage('Jwt token is expired please try again');
            setAlertVariant('danger');
            setOrder({
                ...order,
                isOtpVerified: false,
                isOtpSent: false
            })
        }
    }
    setOrderValidated(true);
};    const sendOtp = useRef(null);
    const verifyOtpForm = useRef(null);
    const orderPlace = useRef(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('success');
    const [message,setMessage] = useState('');
    return(
        <section className="order-form">
            <div className="mt-5">
                {
                        !order.isOtpSent
                        ? 
                        <>
                        <Form ref={sendOtp}
                            noValidate
                            validated={validated}
                            onSubmit={sentOtp}>
                            
                            <Form.Group className="form-group" controlId="email">
                                <Form.Control
                                type="number"
                                placeholder="Mobile Number"
                                isInvalid={!isValidMobileNumber}
                                onChange={handleMobileNumberChange}
                                value={order.mobileNumber}
                                required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please Enter mobile number...
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" disabled={loading}>Sent Otp</Button>
                        </Form>
                        </>
                        
                    : !order.isOtpVerified ?
                    <>
                        <Form ref={verifyOtpForm}
                            noValidate
                            validated={otpFormValidated}
                            onSubmit={verifyOtp}>
                            <Form.Group className="form-group" controlId="email">
                                <Form.Control
                                type="number"
                                placeholder="Enter Otp"
                                onChange={(e) =>
                                    setOrder({ ...order, otp: e.target.value })
                                }
                                value={order.otp}
                                required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please Enter Otp...
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-right mb-3">
                                <Button className="btn btn-transparent" onClick={()=>sendOtp()} disabled={timer > 0}>Resend Otp {timer > 0 && `(${timer}s)`}</Button>
                            </div>
                            <Button type="submit">Verify Otp</Button>
                        </Form>
                    </>
                    : 
                    <>
                    <Form ref={orderPlace}
                        noValidate
                        validated={orderFormValidated}
                        onSubmit={orderPlaced}
                        >
                        <Row>
                            <Col md="6" lg="6" sm="6" xs="12">
                                <Form.Group className="form-group" controlId="name">
                                    <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) => setOrder({ ...order, name: e.target.value })}
                                    value={order.name}
                                    required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                    Please Enter name...
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="6" lg="6" sm="6" xs="12">
                                <Form.Group className="form-group" controlId="name">
                                    <Form.Control
                                    type="number"
                                    placeholder="Mobile Number"
                                    isInvalid={!isValidMobileNumber}
                                    onChange={handleMobileNumberChange}
                                    value={order.mobileNumber}
                                    required
                                    disabled
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                    Please Enter mobile number...
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="12" lg="12" sm="12" xs="12">
                            <Form.Label>Delivery address</Form.Label>
                                
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                    <Form.Group className="form-group" controlId="product">
                                        <Form.Control
                                        type="text"
                                        onChange={(e) =>
                                            setOrder({ ...order, blockName: e.target.value })
                                        }
                                        value={order.blockName}
                                        placeholder="Block Name"
                                        required
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Please enter Block Name...
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                    <Form.Group className="form-group" controlId="product">
                                        <Form.Control
                                        type="text"
                                        onChange={(e) =>
                                            setOrder({ ...order, flat: e.target.value })
                                        }
                                        value={order.flat}
                                        required
                                        placeholder="Flat #"
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                        Please enter Flat #...
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                    </Form>
                </>
                }
            </div>
            <Notifications showAlert={showAlert} setShowAlert={setShowAlert} alertVariant={alertVariant} message={message} />
            {
                loading ?
                <SpinnerGrow />
                : null
            }    
        </section>
    )
}