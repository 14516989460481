export const GalleryData = [{
    imgSrc: 'gallery/gallery-1.JPG',
    title: 'gallery 1'
},
{
    imgSrc: 'gallery/gallery-2.JPG',
    title: 'gallery 2'
},
{
    imgSrc: 'gallery/gallery-3.JPG',
    title: 'gallery 3'
},
{
    imgSrc: 'gallery/gallery-4.JPG',
    title: 'gallery 4'
},
{
    imgSrc: 'gallery/gallery-5.JPG',
    title: 'gallery 5'
},
{
    imgSrc: 'gallery/gallery-6.JPG',
    title: 'gallery 6'
},
{
    imgSrc: 'gallery/gallery-7.JPG',
    title: 'gallery 7'
},
{
    imgSrc: 'gallery/gallery-8.JPG',
    title: 'gallery 8'
},
{
    imgSrc: 'gallery/gallery-9.JPG',
    title: 'gallery 9'
},
{
    imgSrc: 'gallery/gallery-10.JPG',
    title: 'gallery 10'
},
{
    imgSrc: 'gallery/gallery-11.JPG',
    title: 'gallery 11'
},
{
    imgSrc: 'gallery/gallery-12.JPG',
    title: 'gallery 12'
},
{
    imgSrc: 'gallery/gallery-13.JPG',
    title: 'gallery 13'
},
{
    imgSrc: 'gallery/gallery-14.JPG',
    title: 'gallery 14'
},
{
    imgSrc: 'gallery/gallery-15.JPG',
    title: 'gallery 15'
},
{
    imgSrc: 'gallery/gallery-16.JPG',
    title: 'gallery 16'
},
{
    imgSrc: 'gallery/gallery-17.JPG',
    title: 'gallery 17'
},
{
    imgSrc: 'gallery/gallery-18.JPG',
    title: 'gallery 18'
},
{
    imgSrc: 'gallery/gallery-19.JPG',
    title: 'gallery 19'
},
{
    imgSrc: 'gallery/gallery-20.JPG',
    title: 'gallery 20'
},
{
    imgSrc: 'gallery/gallery-21.JPG',
    title: 'gallery 21'
},
{
    imgSrc: 'gallery/gallery-22.JPG',
    title: 'gallery 22'
},
{
    imgSrc: 'gallery/gallery-23.JPG',
    title: 'gallery 23'
},
{
    imgSrc: 'gallery/gallery-24.JPG',
    title: 'gallery 24'
},
{
    imgSrc: 'gallery/gallery-25.JPG',
    title: 'gallery 25'
},
{
    imgSrc: 'gallery/gallery-26.JPG',
    title: 'gallery 26'
},
{
    imgSrc: 'gallery/gallery-27.JPG',
    title: 'gallery 27'
},
{
    imgSrc: 'gallery/gallery-28.JPG',
    title: 'gallery 28'
},
{
    imgSrc: 'gallery/gallery-29.JPG',
    title: 'gallery 29'
},
{
    imgSrc: 'gallery/gallery-30.JPG',
    title: 'gallery 30'
},
{
    imgSrc: 'gallery/gallery-31.JPG',
    title: 'gallery 31'
},
{
    imgSrc: 'gallery/gallery-32.JPG',
    title: 'gallery 32'
},
{
    imgSrc: 'gallery/gallery-33.JPG',
    title: 'gallery 33'
},
{
    imgSrc: 'gallery/gallery-34.JPG',
    title: 'gallery 34'
},
{
    imgSrc: 'gallery/gallery-35.JPG',
    title: 'gallery 35'
},
{
    imgSrc: 'gallery/gallery-36.JPG',
    title: 'gallery 36'
},
{
    imgSrc: 'gallery/gallery-37.JPG',
    title: 'gallery 37'
},
{
    imgSrc: 'gallery/gallery-38.JPG',
    title: 'gallery 38'
},
{
    imgSrc: 'gallery/gallery-39.JPG',
    title: 'gallery 39'
},
{
    imgSrc: 'gallery/gallery-40.JPG',
    title: 'gallery 40'
},
{
    imgSrc: 'gallery/gallery-41.JPG',
    title: 'gallery 41'
},
{
    imgSrc: 'gallery/gallery-42.JPG',
    title: 'gallery 42'
},
{
    imgSrc: 'gallery/gallery-43.JPG',
    title: 'gallery 43'
},
{
    imgSrc: 'gallery/gallery-44.JPG',
    title: 'gallery 44'
},
{
    imgSrc: 'gallery/gallery-45.JPG',
    title: 'gallery 45'
},
{
    imgSrc: 'gallery/gallery-46.JPG',
    title: 'gallery 46'
},
{
    imgSrc: 'gallery/gallery-47.JPG',
    title: 'gallery 47'
},
{
    imgSrc: 'gallery/gallery-48.JPG',
    title: 'gallery 48'
},
{
    imgSrc: 'gallery/gallery-49.JPG',
    title: 'gallery 49'
},
{
    imgSrc: 'gallery/gallery-50.JPG',
    title: 'gallery 50'
},
{
    imgSrc: 'gallery/gallery-51.JPG',
    title: 'gallery 51'
},
{
    imgSrc: 'gallery/gallery-52.JPG',
    title: 'gallery 52'
},
{
    imgSrc: 'gallery/gallery-53.JPG',
    title: 'gallery 53'
},
{
    imgSrc: 'gallery/gallery-54.JPG',
    title: 'gallery 54'
},
{
    imgSrc: 'gallery/gallery-55.JPG',
    title: 'gallery 55'
},
{
    imgSrc: 'gallery/gallery-56.JPG',
    title: 'gallery 56'
},
{
    imgSrc: 'gallery/gallery-57.JPG',
    title: 'gallery 57'
},
{
    imgSrc: 'gallery/gallery-58.JPG',
    title: 'gallery 58'
},
{
    imgSrc: 'gallery/gallery-59.JPG',
    title: 'gallery 59'
},
{
    imgSrc: 'gallery/gallery-60.JPG',
    title: 'gallery 60'
},
{
    imgSrc: 'gallery/gallery-61.JPG',
    title: 'gallery 61'
},
{
    imgSrc: 'gallery/gallery-62.JPG',
    title: 'gallery 62'
},
{
    imgSrc: 'gallery/gallery-63.JPG',
    title: 'gallery 63'
},
{
    imgSrc: 'gallery/gallery-64.JPG',
    title: 'gallery 64'
},
{
    imgSrc: 'gallery/gallery-65.JPG',
    title: 'gallery 65'
},
{
    imgSrc: 'gallery/gallery-66.JPG',
    title: 'gallery 66'
},
{
    imgSrc: 'gallery/gallery-67.JPG',
    title: 'gallery 67'
},
{
    imgSrc: 'gallery/gallery-68.JPG',
    title: 'gallery 68'
},
{
    imgSrc: 'gallery/gallery-69.JPG',
    title: 'gallery 69'
},
{
    imgSrc: 'gallery/gallery-70.JPG',
    title: 'gallery 70'
}]