import { ORDER_PLACE_API_URL, USER_JWT_TOKEN } from "../lib/BaseConstants";

export const OrderSubmision = (data,action) => {
    const token = window.localStorage.getItem(USER_JWT_TOKEN);
    return new Promise((resolve,reject)=>{
        return fetch(`${ORDER_PLACE_API_URL}/${action}/${data.propertyId}`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'aquavivaJwt': token
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
              }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}