import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const Notifications = ({ showAlert, setShowAlert, message,alertVariant }) => {
  useEffect(() => {
    let timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // Hide alert after 4 seconds
    }
    return () => clearTimeout(timeout);
  }, [showAlert, setShowAlert]);

  return (
    <div style={{ marginTop: '20px' }}>
      {showAlert && (
        <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
          {message}
        </Alert>
      )}
    </div>
  );
};

export default Notifications;
