import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../assets/logo.svg";
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
function Header() {
  const [expanded, setexpanded] = useState(false);
  return (
    <Navbar expand="lg" className="bg-white" expanded={expanded}>
    <Container fluid>
      <Navbar.Brand href="#home" className="navbar-brand">
        <Link to={process.env.PUBLIC_URL+'/'} onClick={()=>{setexpanded(false)}}><img src={Logo} alt="aqua-viva-logo" title="aqua-viva-logo" /></Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setexpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="ml-auto">
          <Link to={process.env.PUBLIC_URL+"/about"} className="menu-item nav-link" onClick={()=>{setexpanded(false)}}>About us</Link>
          <HashLink to={process.env.PUBLIC_URL+"/#product-services"} className="menu-item nav-link" onClick={()=>{setexpanded(false)}}>Products/Services</HashLink>
          <Link to={process.env.PUBLIC_URL+"/gallery"} className="menu-item nav-link" onClick={()=>{setexpanded(false)}}>Gallery</Link>
          <Link to={process.env.PUBLIC_URL+"/contact-us"} className="menu-item nav-link" onClick={()=>{setexpanded(false)}}>Contact US</Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default Header;
