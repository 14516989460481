import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomPage from "../views/HomPage";
import MainLayout from "../layouts/MainLayout";
import About from "../views/About";
import ContactUs from "../views/ContactUs";
import Gallery from "../views/Gallery";
import Shop from "../views/Shop";
import OrderConfirmation from "../views/OrderConfirmation";
import Enquiry from "../views/Enquiry";
import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsConditons from "../views/TermsConditions";
import ShippingPolicy from "../views/ShippingPolicy";
import RefundPolicy from "../views/Refund-policy";


const routesPaths = createBrowserRouter([
    {
  
      element: <MainLayout />,
      path: `/`,
      children: [
        { path: '', element: <HomPage /> },
        { path: 'about', element: <About /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'gallery', element: <Gallery /> },
        { path: 'shop', element: <Shop /> },
        { path: 'enquiry', element: <Enquiry /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-conditions', element: <TermsConditons />},
        {path: 'shipping-policy',element:<ShippingPolicy />},
        {path:'refund-policy',element:<RefundPolicy />},
        {path: 'order-confirm', element: <OrderConfirmation />}
      ]
    }
  
]);
function Router() {
    return (
    <RouterProvider router={routesPaths} />
    )
}
export default Router;
