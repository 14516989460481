/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { USER_JWT_TOKEN } from '../lib/BaseConstants';
import { JwtTokenDecode } from '../lib/Utilts';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState({
    customerId:'',
    mobileNumber: "",
    propertyName: "",
    propertyId:"",
    propertyPartitionKey:"",
    productName: "Aqua viva 20 Ltr bottle Jar",
    quantity: 1,
    deliveryAddress: "",
    isOtpSent: false,
    otp:'',
    isOtpVerified: false,
    orderId: "",
    blockName: "",
    flat: "",
    name: '',
    amount:85,
    currency:"INR",
    uDeliveryDate:new Date(),
    bottlesQty:0,
    status: '',
    paymentInfo:[],
    customerAddressId:''
  });
  const [isOrderInitialized, setIsOrderInitialized] = useState(false);
  useEffect(()=>{
    const token = localStorage.getItem(USER_JWT_TOKEN);
    const tokenData = JwtTokenDecode(token);
    if(tokenData) {
      const userInfo = tokenData.data.userEntity;
      setOrder({
        ...order,
        customerId: userInfo._id,
        mobileNumber: userInfo.mobileNumber,
        isOtpSent: userInfo.isPhoneVerified?true:false,
        isOtpVerified: userInfo.isPhoneVerified?true:false,
        name: userInfo.name,
        propertyName: userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1].name:'':'',
        propertyId: userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1]._id:'':'',
        propertyPartitionKey:userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1].partitionKey:'':'',

        blockName: userInfo?userInfo.addresses && userInfo.addresses.length>0?userInfo.addresses[0].apartmentBlock?userInfo.addresses[0].apartmentBlock:'':'':'',
        flat:userInfo?userInfo.addresses && userInfo.addresses.length>0?userInfo.addresses[0].appartmentFlat?userInfo.addresses[0].appartmentFlat:'':'':'',
        bottlesQty: userInfo.bottlesQty?userInfo.bottlesQty:0,
        customerAddressId: (userInfo.addresses && userInfo.addresses.length>0)?userInfo.addresses[0]._id:'',
        status: userInfo.status,
        paymentInfo: userInfo.advancePayments
      })
      setIsOrderInitialized(true); // Mark order as initialized
    } else {
      setIsOrderInitialized(true); // Mark order as initialized whether token exists or not
    }
    
  },[]);
  return <OrderContext.Provider value={{ order, setOrder,isOrderInitialized }}>{children}</OrderContext.Provider>;
};

export const useOrderContext = () => useContext(OrderContext);
