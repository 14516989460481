import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import { Link } from "react-router-dom";
export default function RefundPolicy() {
  return (
    <section className="gallery-page">
      <Container fluid>
        <Row>
          <Col md="12" lg="12" sm="12" xs="12">
            <div className="back-icon">
              <Link to="/">
                <img src={PrevArrow} alt="PrevArrow" title="PrevArrow" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col
            md="12"
            lg="12"
            sm="12"
            xs="12"
            className="drinking-watercontent privacy-policy"
          >
            <h1>Refund Policy</h1>
            <div className="policy_body mt-3">
              <h2>Returns, Refunds, Cancellations, and Exchanges</h2>
              <ul>
                <li>
                  Customers can return their order within 15 days after the
                  order has been delivered. Please note that only unused and
                  undamaged products, with all their labels and tags completely
                  intact, in original packaging, and eligible for return, can be
                  returned.
                </li>
                <li>
                  Return of the product can be done in below cases: - Size or
                  Fitness issue - Did not like the quality/design - Received a
                  damage product - Wrong product received
                </li>
              </ul>
              <ul>
                <li>
                  In case you have purchased a DJ and C beverages product from
                  anywhere other than the DJ and C beverages website, the return
                  policy of the source will apply.{" "}
                </li>
                <li>
                  To request a return/replacement, please text our WhatsApp
                  chatbot number, +91 98488 88828, or mail us at
                  contactus@aquavivawater.in{" "}
                </li>
                <li>
                  We have a reverse pick-up facility for most pin codes. For pin
                  codes that are non-serviceable by our courier partners, you
                  will have to self-ship the product(s). Shipping costs up to
                  INR 70 will be refunded to your original source of
                  payment/bank account.{" "}
                </li>
              </ul>
              <h2>Returns</h2>
              <ul>
                <li>
                  In case the product is not delivered as promised, the product
                  quality will be checked and refunded in 24 hrs
                </li>
              </ul>
              <h2>Refunds</h2>
              <ul>
                <li>
                 {/* Once we receive the product at our warehouse and once the
                  product passes quality control, a refund is initiated to your
                  original mode of payment. In case your order was placed in COD
                  mode, our team will reach out to you for your bank account
                  details, wherein we will process the refund. Please note that
                  this process takes 5-7 days.{" "}
  */}We are having 11 days refund policy. Refund will be processed in 7 to 11 working days.
                </li>
                {/* <li>
                  DJ and C beverages reserves the right to deny a refund if a
                  product doesn’t pass our quality check parameters. In such a
                  case, we will reship the original product to you.
</li> */}
              </ul>
              <h2>Replacement</h2>
              <ul>
                <li>
                  We offer replacements for products where you may face a size
                  and fit issue, or unsatisfied with the quality. The return
                  conditions are applicable as mentioned above.
                </li>
                <li>
                  We will dispatch the replacement within 48 hours after the
                  product has been picked up.{" "}
                </li>
              </ul>
              <h2>Cancellation</h2>
              <ul>
                <li>
                  You can cancel your order before 10 hrs of delivery. Please
                  email us at contactus@aquavivawater.in or raise a ticket on
                  +91 98488 88828 to request a cancellation. We do not provide
                  the provision to cancel the order on the website as of right
                  now.{" "}
                </li>
              </ul>
              <h4>
                **DJ and C beverages reserves the right to cancel any order
                without pre-confirming the customer at any time and may verify
                any order before shipping the same to the customer, which may
                include having verbal or written confirmation from the customer
                regarding addresses, quantities, phone numbers, etc.
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
