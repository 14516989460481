import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import ContactUsImage from "../assets/contact-image.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ContactDataController from "../controllers/ContactDataController";
import { useRef, useState } from "react";
export default function ContactUs() {
  const [contact,setContact] = useState({name:'',email:'',mobileNumber:''});
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
    const handleMobileNumberChange = (event) => {
    const input = event.target.value;
    // Regular expression to match a 10-digit mobile number starting with 7, 8, or 9
    const mobileNumberRegex = /^[789]\d{9}$/;
    setContact({
        ...contact,
        mobileNumber: input
    });
    setIsValidMobileNumber(mobileNumberRegex.test(input));
  };
  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            event.preventDefault();
            ContactDataController(contact).then((res)=>{
                if(res.success===1) {
                   alert('Thanks for contacting us.our team will get back to you soon.');
                   formRef.current.reset();
                    setContact({ name: '', email: '', mobileNumber: '' });
                    setIsValidMobileNumber(true);
                    setValidated(false);
                } else {
                    alert(res.message);
                }
            }).catch((err)=>{
                alert('Something went wrong please try again');
            })
        }
        setValidated(true);
    };
  return (
    <section className="contact-us-page">
      <Container fluid>
        <Row>
          <Col md="12" lg="12" sm="12" xs="12">
            <div className="back-icon">
            <Link to="/"><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
            </div>
          </Col>
        </Row>
        </Container>
        <Container fluid className="padding-left-none">
        <Row className="mt-5">
          <Col md="7" lg="7" sm="7" xs="12" className="about-us-bg">
            <div className="">
              <img
                src={ContactUsImage}
                alt="ContactUsImage"
                title="ContactUsImage"
                className="w-100 contact-image"
              />
            </div>
          </Col>
          <Col md="5" lg="5" sm="5" xs="12">
            <h3>Contact Us</h3>
            <p>
              We invite you to experience the essence of Aqua Viva. A visit to
              our plant will immerse you in the intricacies of our production
              process, showcase the advanced technology we utilize, and
              demonstrate our unwavering dedication to quality. It’s an
              opportunity to witness our commitment to innovation and to
              maintaining industry-leading standards.
            </p>
            <Form className="mt-5" ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="name" className="form-group">
                <Form.Control type="text" placeholder="Name" onChange={e=>setContact({...contact,name:e.target.value})} value={contact.name} required></Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Enter name...
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email" className="form-group">
                <Form.Control type="text" placeholder="Email Id" onChange={e=>setContact({...contact,email:e.target.value})} value={contact.email} required></Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please Enter email...
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="mobileNumber" className="form-group">
                <Form.Control
                  type="number"
                  placeholder="Mobile Number"
                  isInvalid={!isValidMobileNumber} onChange={handleMobileNumberChange} value={contact.mobileNumber} required></Form.Control>
                  <Form.Control.Feedback type="invalid">
                      Please Enter mobile number...
                  </Form.Control.Feedback>
              </Form.Group>
                <div className="mt-5">
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                </div>
              </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
