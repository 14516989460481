import { Spinner } from "react-bootstrap";

export default function SpinnerGrow() {
    return (
        <div className="spinner-loader">
             <Spinner animation="border" size="xxl" // You can adjust the size as per your preference
              role="status"
              aria-hidden="true"
              style={{ marginRight: '8px' }} />
        </div>
    )
}