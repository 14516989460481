export  const SEND_OTP_API_URL = process.env.REACT_APP_BASE_API_URL+'/pre-register/type/';
export const VERIFY_OTP_API_URL = process.env.REACT_APP_BASE_API_URL+'/verify/type/';
export const UPDATE_ADDRESS_API_URL = process.env.REACT_APP_BASE_API_URL+'/customer/address';
export const ORDER_PLACE_API_URL = process.env.REACT_APP_BASE_API_URL+'/user/order';
export const USER_LOGIN_API_URL = process.env.REACT_APP_BASE_API_URL+'/user/login';
export const REFRESH_TOKEN_API_URL = process.env.REACT_APP_BASE_API_URL+'/customer/refresh/token';
export const GET_PROPERTIES_LIST_API_URL = process.env.REACT_APP_BASE_API_URL+'/property';
export const REQUEST_ADVANCE_PAYMENT = process.env.REACT_APP_BASE_API_URL+'/customer';
export const PROPERTY_DETAILS = process.env.REACT_APP_BASE_API_URL + '/property'



export const USER_LOGIN_DATA = 'aquiva-viva-userInfo';
export const USER_JWT_TOKEN='aquiva-user-jwttoken';
export const USER_MOBILE_VERIFIED = 'aquiva-user-mobile-verified';