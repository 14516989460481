import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrevArrow from "../assets/arrow-prev.svg";
import shopBanner from '../assets/products-steps-form.png'
import LogoWhite from '../assets/logo-white.png'
import { Link } from "react-router-dom";
import { OrderProvider } from "../storeManagement/OrderContext";
import OrderForm from "../components/OrderForm";
import { useEffect } from "react";
import { JwtTokenDecode } from "../lib/Utilts";
import { USER_JWT_TOKEN } from "../lib/BaseConstants";
export default function Shop() {
  useEffect(()=>{
    const token = localStorage.getItem(USER_JWT_TOKEN);
    const tokenData = JwtTokenDecode(token);
    let username = 'first-time';
    let mobileNumber = 'undefined';
    if(tokenData) {
      const userInfo = tokenData.data.userEntity;
      username = userInfo.name;
      mobileNumber = userInfo.mobileNumber;
    }
    if (!window.smartlook.initialized) {
      window.smartlook('init', process.env.SMART_LOOK_KEY);
    }

    // Tag the session with username, mobile number, and the current URL
    window.smartlook('tag', `${username}-${mobileNumber}-${window.location.href}`);

    // Optionally track a custom event when the Contact Page mounts
    window.smartlook('track', 'Page View', {
      page: 'Contact Page',
      username: username,
      mobileNumber: mobileNumber,
    });

    // Function to stop Smartlook recording
    const stopRecording = () => {
      window.smartlook('disableTracking');
      // Optionally perform cleanup actions here if needed
      // Example: Clear any timers, remove event listeners, etc.
    };

    // Attach event listener for beforeunload event to stop recording
    window.addEventListener('beforeunload', stopRecording);

    // Cleanup function to remove event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', stopRecording);
    };
      }, []);
    return(
        <section className="shop-page">
            <Container fluid>
            {
              window.innerWidth>768 ?
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <div className="back-icon">
                  <Link to="/"><img src={PrevArrow} alt="PrevArrow" title="PrevArrow" /></Link>
                  </div>
                </Col>
              </Row>
              :null
            }
        </Container>
        <Container fluid className="padding-left-none">
        <Row className="mt-5 space-mobile-top-margin">
          {
            window.innerWidth>768 ?
            <Col md="7" lg="7" sm="7" xs="12" className="about-us-bg">
            <div className="">
                <div className="shop-logo">
                    <img src={LogoWhite} alt="LogoWhite" title="LogoWhite" />
                </div>
              <img
                src={shopBanner}
                alt="shopBanner"
                title="shopBanner"
                className="w-100 contact-image"
              />
            </div>
          </Col>
          : null
          }
          <Col md="5" lg="5" sm="5" xs="12">
            <div className="steps-form">
              <OrderProvider>
                <OrderForm />
              </OrderProvider>
            </div>
          </Col>
          </Row>
          </Container>
        </section>
    )
}