/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col, InputGroup, Accordion, Alert, Modal } from "react-bootstrap";
import { useOrderContext } from "../storeManagement/OrderContext";
import {
  RequestAdvancePayment,
  SendOtp,
  UpdateAddress,
  VerifyOtp,
  refreshToken,
} from "../services/UserService";
import {
  USER_JWT_TOKEN,
  USER_LOGIN_DATA,
  USER_MOBILE_VERIFIED,
} from "../lib/BaseConstants";
import Notifications from "./Notification";
import SpinnerGrow from "./SpinnerGrow";
import { JwtTokenDecode } from "../lib/Utilts";
import { OrderSubmision } from "../services/OrderService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { getPropertyDetails, getPropertyList } from "../services/PropertyService";
export default function OrderForm() {
  const { order, setOrder, isOrderInitialized } = useOrderContext();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tokenRefresh,setTokenRefresh] = useState(false);
  const [isLoadedProperty, setIsPropertyLoaded] = useState(false);
  const [propertyAmount,setPropertyAmount] = useState(0);
  const [totalAmountPrice,setTotalAmountPrice] = useState(0);
  const [payNowEnabled, setpayNowEnabled] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // mobile number validation
  const handleMobileNumberChange = (event) => {
    const input = event.target.value;
    // Regular expression to match a 10-digit mobile number starting with 7, 8, or 9
    const mobileNumberRegex = /^[789]\d{9}$/;
    setOrder({
      ...order,
      mobileNumber: input,
    });
    setIsValidMobileNumber(mobileNumberRegex.test(input));
  };
  const [timer, setTimer] = useState(60);
  const [bottlesTaken, setBottlesTaken] = useState(
    order.orderBottlesTaken || ""
  );
  const [previousBottlesTaken, setPreviousBootlesTaken] = useState(
    order.orderBottlesTaken || ""
  );
  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const isAfter5PM =
      currentHour > 17 || (currentHour === 17 && currentMinute > 0);

    if (isAfter5PM) {
      // If it's after 5 PM, add one day to the current date
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setOrder({
      ...order,
      uDeliveryDate: currentDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOrderInitialized && !order.isOtpSent && !isLoaded) {
      void propertiesList();
    }
    if(isOrderInitialized && order.propertyId!=='' && !isLoadedProperty) {
      void propertiesDetails();
    }
    setBottlesTaken(order.bottlesQty);
    setPreviousBootlesTaken(order.bottlesQty);
    setpayNowEnabled(order.bottlesQty > 0 ? false : true);
    if(isOrderInitialized && order.isOtpVerified && order.bottlesQty<=0 && !tokenRefresh) {
      void refreshTokenUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderInitialized, isLoaded]);
  // adding the razorpay script to the html
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      console.log("Razorpay script loaded");
      // Now Razorpay object should be available
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timer]);
  const [validated, setValidated] = useState(false);
  const [orderFormValidated, setOrderValidated] = useState(false);
  const [otpFormValidated, setOtpValidated] = useState(false);

  // sending the otp
  const sentOtp = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      if (isValidMobileNumber) {
        setLoading(true);
        // send an otp to the user
        const data = {
          propertyId: order.propertyId,
          propertyName: order.propertyName,
          propertyPartitionKey: order.propertyPartitionKey,
        };
        SendOtp(order.mobileNumber, data)
          .then((res) => {
            setShowAlert(true);
            setMessage("Otp sent to your mobile number");
            setOrder({
              ...order,
              isOtpSent: true,
              isOtpVerified: false,
            });
            setValidated(false);
          })
          .catch((err) => {
            setShowAlert(true);
            setMessage(
              err.data
                ? err.data
                : err.message
                ? err.message
                : err.error
                ? err.error
                : "Otp not sent to your mobile number"
            );
            setAlertVariant("danger");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    setValidated(true);
  };

  // verify otp
  const verifyOtp = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setLoading(true);
      const payload = {
        mobileNumber: order.mobileNumber,
        otp: order.otp,
      };
      await VerifyOtp(payload)
        .then((res) => {
          setShowAlert(true);
          window.localStorage.setItem(
            USER_LOGIN_DATA,
            JSON.stringify(res.user)
          );
          window.localStorage.setItem(USER_MOBILE_VERIFIED, "true");
          window.localStorage.setItem(USER_JWT_TOKEN, res.jwt);
          setValidated(false);
          setAlertVariant("success");
          setMessage("Otp is verified successfully");
          const userInfo = res.user;
          setOrder({
            ...order,
            customerId: userInfo._id,
            propertyName: userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1].name:'':'',
            propertyId: userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1]._id:'':'',
            propertyPartitionKey:userInfo?userInfo.propertyInfos && userInfo.propertyInfos.length>0?userInfo.propertyInfos[userInfo.propertyInfos.length-1].partitionKey:'':'',

            isOtpVerified: true,
            isOtpSent: true,
            name: res.user.name,
            blockName: userInfo
              ? userInfo.addresses && userInfo.addresses.length > 0
                ? userInfo.addresses[0]
                    .apartmentBlock
                  ? userInfo.addresses[0]
                      .apartmentBlock
                  : ""
                : ""
              : "",
            flat: userInfo
              ? userInfo.addresses && userInfo.addresses.length > 0
                ? userInfo.addresses[0]
                    .appartmentFlat
                  ? userInfo.addresses[0]
                      .appartmentFlat
                  : ""
                : ""
              : "",
            bottlesQty: userInfo.bottlesQty ? userInfo.bottlesQty : 0,
            customerAddressId:
              userInfo.addresses && userInfo.addresses.length > 0
                ? userInfo.addresses[0]._id
                : "",
            status: userInfo.status,
            paymentInfo: userInfo.advancePayments,

          });
          setBottlesTaken(userInfo.bottlesQty ? userInfo.bottlesQty : 0);
          setPreviousBootlesTaken(userInfo.bottlesQty ? userInfo.bottlesQty : 0);
          setpayNowEnabled(userInfo.bottlesQty && userInfo.bottlesQty > 0 ? false : true);
        })
        .catch((err) => {
          setShowAlert(true);
          setMessage(
            err.data
              ? err.data
              : err.message
              ? err.message
              : err.error
              ? err.error
              : "Otp not sent to your mobile number"
          );
          setAlertVariant("danger");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setOtpValidated(true);
  };


  // update the user details 
  const updateAddressDetails = async () => {
    if(order.blockName==='' || order.flat==='') {
      setShowAlert(true);
      setMessage(
        "Please enter the block name and flat not empty"
      );
      setAlertVariant("danger");
      return;
    }
    const token = localStorage.getItem(USER_JWT_TOKEN);
    const tokenData = JwtTokenDecode(token);
    await changeAddress(tokenData,order);
  }

  // update the address if the data is added or updated
  const changeAddress = async (tokenData, order) => {
    const userInfo = tokenData.data.userEntity;
    if (userInfo.addresses && userInfo.addresses.length > 0) {
      if (
        userInfo.addresses[0].apartmentBlock !==
          order.blockName ||
        userInfo.addresses[0].appartmentFlat !==
          order.flat
      ) {
        const data = {
          name: order.name,
          apartmentBlock: order.blockName,
          appartmentFlat: order.flat,
          propertyId: order.propertyId,
          customerAddressId:
            userInfo.addresses[0]._id,
        };
        setLoading(true);
        await UpdateAddress(data)
          .then((res) => {
            window.localStorage.setItem(
              USER_LOGIN_DATA,
              JSON.stringify(res.user)
            );
            window.localStorage.setItem(USER_MOBILE_VERIFIED, "true");
            window.localStorage.setItem(USER_JWT_TOKEN, res.jwt);
          })
          .catch((err) => {
            setShowAlert(true);
            setMessage(
              err.data
                ? err.data
                : err.message
                ? err.message
                : err.error
                ? err.error
                : "Update Address is not found"
            );
            setAlertVariant("danger");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      
      const data = {
        name: order.name,
        apartmentBlock: order.blockName,
        appartmentFlat: order.flat,
        propertyId: order.propertyId,
        customerAddressId: "",
      };
      setLoading(true);
      await UpdateAddress(data)
        .then((res) => {
          window.localStorage.setItem(
            USER_LOGIN_DATA,
            JSON.stringify(res.user)
          );
          window.localStorage.setItem(USER_MOBILE_VERIFIED, "true");
          window.localStorage.setItem(USER_JWT_TOKEN, res.jwt);
        })
        .catch((err) => {
          setShowAlert(true);
          setMessage(
            err.data
              ? err.data
              : err.message
              ? err.message
              : err.error
              ? err.error
              : "Update Address is not found"
          );
          setAlertVariant("danger");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // placing the order
  const orderPlaced = async () => {
    const form = document.getElementById('orderForm');

      if (form.checkValidity() === false) {
        form.classList.add('was-validated');
        // Optionally, you can scroll to the first invalid input field
        form.querySelector(':invalid').scrollIntoView({ behavior: 'smooth' });
      } else {
        form.classList.remove('was-validated');
      if (Number(order.quantity) > Number(order.bottlesQty)) {
        setShowAlert(true);
        setMessage(
          "Quantity must be less than or equal to the  customer adv payment please try again"
        );
        setAlertVariant("danger");
        return;
      }
      const action = 'PAY_NOW';
      const token = localStorage.getItem(USER_JWT_TOKEN);
      const tokenData = JwtTokenDecode(token);
      if (tokenData) {
        await changeAddress(tokenData, order);
        const orderData = {
          item: {
            productName: order.productName,
            qty: order.quantity,
            amount: order.amount,
          },
          propertyId: order.propertyId,
          propertyName: order.propertyName,
          propertyPartitionKey: order.propertyPartitionKey,
          amount: order.amount,
          currency: order.currency,
          uDeliveryDate: new Date(order.uDeliveryDate).getTime(),
        };
        setLoading(true);
        await OrderSubmision(orderData, action)
          .then((res) => {
            setShowAlert(true);
            if (action === "PAY_NOW") {
              const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: res.totalAmount * 100, // Amount in paise
                currency: "INR",
                order_id: res.orderId,
                name: "Aqua Viva",
                image:
                  "https://dotwebideas.com/development/aqua-viva/logo192.png",
                description: `Payment for Order id ${res.orderId}`,
                handler: function (response) {
                  console.log("Payment successful:", response);
                  paymentSuccessResponse(response);
                  // Handle success (e.g., show success message)
                },
                notes: {
                  address: order.blockName,
                },
                prefill: {
                  name: order.name,
                  email: order.email,
                  mobileNumber: order.mobileNumber,
                },
                theme: {
                  color: "#177eaf",
                },
                modal: {
                  ondismiss: function () {
                    // Failure handler
                    alert("Payment failed or cancelled");
                    // history(`/order-confirm?id=${res.orderId}&status=FAILURE`);
                  },
                },
              };
              const rzp = new window.Razorpay(options);
              rzp.open();
            } else {
              setMessage(
                "Your order is placed successfully. Our team will get back to you soon"
              );
              alert(
                "Your order is placed successfully. Our team will get back to you soon"
              );
              setAlertVariant("success");
              history(`/order-confirm?id=${res.id}`);
            }
          })
          .catch((err) => {
            setShowAlert(true);
            setMessage(
              err.data
                ? err.data
                : err.message
                ? err.message
                : err.error
                ? err.error
                : "Order is not placed please try again"
            );
            setAlertVariant("danger");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setMessage("Jwt token is expired please try again");
        setAlertVariant("danger");
        setOrder({
          ...order,
          isOtpVerified: false,
          isOtpSent: false,
        });
      }
    }
    setOrderValidated(true);
  };

  useEffect(() => {
    setTotalAmountPrice(propertyAmount * order.quantity);
  }, [order.quantity, propertyAmount]);

  // increment quantity
  const incrementQuantity = () => {
    setOrder(prevOrder => {
      const updatedQuantity = prevOrder.quantity + 1;
      // Check conditions based on updated quantity
      if (updatedQuantity > previousBottlesTaken) {
        setpayNowEnabled(true);
        setShowAlert(true);
        setMessage(
          `Order quantity cannot exceed ${previousBottlesTaken} bottles, as the advance covers only that many.`
        );
        setAlertVariant("danger");
      } else {
        setpayNowEnabled(false);
      }
  
      // Return updated order object
      return { ...prevOrder, quantity: updatedQuantity };
    });
  };

  // decrement quantity
  const decrementQuantity = () => {
    if (order.quantity > 1) {
        setOrder(prevOrder => {
        const updatedQuantity = prevOrder.quantity - 1;
  
        // Check conditions based on updated quantity
        if (updatedQuantity > previousBottlesTaken) {
          setpayNowEnabled(true);
          setShowAlert(true);
          setMessage(
            `Order quantity cannot exceed ${previousBottlesTaken} bottles, as the advance covers only that many.`
          );
          setAlertVariant("danger");
        } else {
          setpayNowEnabled(false);
        }
        
        // Return updated order object
        return { ...prevOrder, quantity: updatedQuantity };
        })
      } 
  };
  // increment quantity
  const incremenBottlesQuantity = () => {
    setBottlesTaken(bottlesTaken+1);
   };

  // decrement quantity
  const decremenBootlestQuantity = () => {
    if (bottlesTaken > 1) {
     setBottlesTaken(bottlesTaken-1);
    }
  };

  // update the quantity
  const sendOtp = useRef(null);
  const verifyOtpForm = useRef(null);
  const orderPlace = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("success");
  const [message, setMessage] = useState("");
  // Check if current time is greater than 5 PM
  const isAfter5PM = () => {
    const currentDate = new Date();
    return currentDate.getHours() >= 17; // 17 represents 5 PM in 24-hour format
  };

  const futureDate = () => {
    var currentDate = new Date();
    return currentDate.setDate(currentDate.getDate() + 1);
  };
  const [error, setError] = useState(false);
  const handleFlatInputChange = (e) => {
    const { value } = e.target;
    // Remove any non-digit characters from the input value
    if (!isNaN(value)) {
      setOrder({ ...order, flat: value });
      setError(false);
    } else {
      setError(true);
    }
  };

  const paymentSuccessResponse = (data) => {
    const id = data.razorpay_payment_id; // Example id
    const orderId = data.razorpay_order_id;
    setMessage(
      "Your order is placed successfully. Our team will get back to you soon"
    );
    alert(
      "Your order is placed successfully. Our team will get back to you soon"
    );
    setAlertVariant("success");
    history(`/order-confirm?id=${orderId}&status=PAID&paymentId=${id}`);
  };

  // pay advance for the bootles
  const handleAdvPayment = async () => {
    if (Number(bottlesTaken) <= Number(previousBottlesTaken)) {
      setShowAlert(true);
      setMessage(
        "customer adv payment must be greater then bootles qty please try again"
      );
      setAlertVariant("danger");
      return;
    }
    setLoading(true);
    const data = {
      qty: bottlesTaken,
    };
    await RequestAdvancePayment(
      order.customerId,
      order.propertyId,
      "GENERATE_ORDER",
      data
    )
      .then((res) => {
        setShowAlert(true);
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: res.totalPrice * 100, // Amount in paise
          currency: "INR",
          order_id: res.id,
          name: "Aqua Viva",
          image: "https://dotwebideas.com/development/aqua-viva/logo192.png",
          description: `Payment for Order id ${res.id}`,
          handler: function (response) {
            console.log("Payment successful:", response);
            setBottlesTaken(bottlesTaken);
            setPreviousBootlesTaken(bottlesTaken);
            setpayNowEnabled(bottlesTaken > 0 ? false : true);
            setTimeout(() => {
              refreshTokenUser();
            }, 3000);
            // Handle success (e.g., show success message)
          },
          notes: {
            address: order.blockName,
          },
          prefill: {
            name: order.name,
            email: order.email,
            mobileNumber: order.mobileNumber,
          },
          theme: {
            color: "#177eaf",
          },
          modal: {
            ondismiss: function () {
              // Failure handler
              alert("Payment failed or cancelled for the advance Payment");
            },
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      })
      .catch((err) => {
        setShowAlert(true);
        setMessage(
          err.data
            ? err.data
            : err.message
            ? err.message
            : err.error
            ? err.error
            : "customer adv payment request is not getting please try again"
        );
        setAlertVariant("danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [propertyOptions, setPropertyOptions] = useState([]);
  // refresh the token and update the bottles qty
  const refreshTokenUser = async (data) => {
    setLoading(true);
    await refreshToken()
      .then((res) => {
        window.localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(res.user));
        window.localStorage.setItem(USER_MOBILE_VERIFIED, "true");
        window.localStorage.setItem(USER_JWT_TOKEN, res.jwt);
        setOrder({
          ...order,
          status: res.user.status,
          bottlesQty: res.user.bottlesQty?res.user.bottlesQty:0,
          paymentInfo: res.user.advancePayments,
        });
        setBottlesTaken(res.user.bottlesQty?res.user.bottlesQty:0);
        setPreviousBootlesTaken(res.user.bottlesQty?res.user.bottlesQty:0);
        setpayNowEnabled(res.user.bottlesQty&& res.user.bottlesQty > 0 ? false : true);
        
      })
      .catch((err) => {
        setShowAlert(true);
        setMessage(
          err.data
            ? err.data
            : err.message
            ? err.message
            : err.error
            ? err.error
            : "Token is not Refreshed please try again"
        );
        setAlertVariant("danger");
      }).finally(()=>{
        setLoading(false);
        setTokenRefresh(true);
      });
  };
  const propertiesList = async () => {
    await getPropertyList()
      .then((res) => {
        setPropertyOptions(res);
        if (res && res.length > 0) {
          setOrder({
            ...order,
            propertyName: res[0].name,
            propertyId: res[0]._id,
            propertyPartitionKey: res[0].partitionKey,
          });
          setPropertyAmount(res[0].amount);
          setTotalAmountPrice(res[0].amount);
          setIsPropertyLoaded(true);
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        setShowAlert(true);
        setMessage(
          err.data
            ? err.data
            : err.message
            ? err.message
            : err.error
            ? err.error
            : "Properties not getting please try again"
        );
      });
  };

  // get the property deatils
  const propertiesDetails = async () => {
    await getPropertyDetails(order.propertyId)
      .then((res) => {
        setPropertyAmount(res.amount);
        setTotalAmountPrice(res.amount);
        setIsPropertyLoaded(true);
      })
      .catch((err) => {
        setShowAlert(true);
        setMessage(
          err.data
            ? err.data
            : err.message
            ? err.message
            : err.error
            ? err.error
            : "Properties details not getting please try again"
        );
      });
  };

  const selectProperty = (event) => {
    const selectedId = event.target.value;
    const selectedOption = propertyOptions.find(
      (option) => option._id === selectedId
    );
    setOrder({
      ...order,
      propertyName: selectedOption.name,
      propertyId: selectedOption._id,
      propertyPartitionKey: selectedOption.partitionKey,
    });
    setPropertyAmount(selectedOption.amount);
    setTotalAmountPrice(selectedOption.amount);
    setIsPropertyLoaded(true);
  };
  const logout = () =>{
    window.localStorage.removeItem(USER_LOGIN_DATA);
    window.localStorage.removeItem(USER_MOBILE_VERIFIED);
    window.localStorage.removeItem(USER_JWT_TOKEN);
    window.location.reload();
  }
  return (
    <section className="order-form">
      {!order.isOtpSent ?
      '': !order.isOtpVerified?'':
            <div className='text-right'>
              <Button className="btn btn-danger" onClick={logout}>Logout</Button>
            </div>
      }
      <h3>
        {" "}
        {!order.isOtpSent
          ? "First time login Please verify your mobile number"
          : !order.isOtpVerified
          ? "First time login Please verify your mobile number"
          : "Place Order"}
      </h3>
      <div className="mt-5 space-form-top">
        {!order.isOtpSent ? (
          <>
            <Form
              ref={sendOtp}
              noValidate
              validated={validated}
              onSubmit={sentOtp}
            >
              <Form.Group className="form-group" controlId="product">
                <Form.Label>Select Property</Form.Label>
                <Form.Select
                  onChange={selectProperty}
                  value={order.propertyName}
                  required
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {propertyOptions.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select any Property...
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="email">
                <Form.Control
                  type="number"
                  placeholder="Mobile Number"
                  isInvalid={!isValidMobileNumber}
                  onChange={handleMobileNumberChange}
                  value={order.mobileNumber}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please Enter mobile number...
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={loading}>
                Sent Otp
              </Button>
            </Form>
            <Notifications
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              alertVariant={alertVariant}
              message={message}
            />
            {loading ? <SpinnerGrow /> : null}
          </>
        ) : !order.isOtpVerified ? (
          <>
            <Form
              ref={verifyOtpForm}
              noValidate
              validated={otpFormValidated}
              onSubmit={verifyOtp}
            >
              <Form.Group className="form-group" controlId="email">
                <Form.Control
                  type="number"
                  placeholder="Enter Otp"
                  onChange={(e) => setOrder({ ...order, otp: e.target.value })}
                  value={order.otp}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please Enter Otp...
                </Form.Control.Feedback>
              </Form.Group>
              <div className="text-right mb-3">
                <Button
                  className="btn btn-transparent"
                  onClick={() => sendOtp()}
                  disabled={timer > 0}
                >
                  Resend Otp {timer > 0 && `(${timer}s)`}
                </Button>
              </div>
              <Button type="submit">Verify Otp</Button>
            </Form>
            <Notifications
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              alertVariant={alertVariant}
              message={message}
            />
            {loading ? <SpinnerGrow /> : null}
          </>
        ) : (
          <>
            <Form
              ref={orderPlace}
              noValidate
              validated={orderFormValidated}
              id="orderForm"
            >
              <Accordion defaultActiveKey="0" className="shop-accordian">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Customer Details</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md="6" lg="6" sm="6" xs="12">
                        <Form.Label>Mobile Number</Form.Label>
                        <h6 className="form-default-text">{order.mobileNumber}</h6>
                      </Col>
                      <Col md="6" lg="6" sm="6" xs="12">
                        <Form.Label>Property</Form.Label>
                        <h6 className="form-default-text">{order.propertyName}</h6>
                      </Col>
                      <Col md="12" lg="12" sm="12" xs="12">
                        <Form.Label>Name</Form.Label>
                        <Form.Group className="form-group" controlId="name">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            onChange={(e) =>
                              setOrder({ ...order, name: e.target.value })
                            }
                            value={order.name}
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please Enter name...
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      
                      <Col md="12" lg="12" sm="12" xs="12">
                        <Form.Label>Order Delivery Location</Form.Label>

                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <Form.Label>Block name</Form.Label>
                            <Form.Group
                              className="form-group"
                              controlId="product"
                            >
                              <Form.Control
                                type="text"
                                onChange={(e) =>
                                  setOrder({
                                    ...order,
                                    blockName: e.target.value,
                                  })
                                }
                                value={order.blockName}
                                placeholder="Block Name"
                                required
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please enter Block Name...
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                          <Form.Label>Flat No</Form.Label>
                            <Form.Group
                              className="form-group"
                              controlId="product"
                            >
                              <Form.Control
                                type="number"
                                pattern="[0-9]*"
                                onChange={handleFlatInputChange}
                                value={order.flat}
                                required
                                placeholder="Flat No #"
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please enter correct Flat Number only #...
                              </Form.Control.Feedback>
                              {error ? (
                                <div class="invalid-feedback">
                                  Please enter Flat number field only...
                                </div>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <Button
                              onClick={()=>updateAddressDetails()}
                              className="btn-pay-later ml-sm"
                            >
                              Update Address
                          </Button>
                      </div>
                      </Col>
                    </Row>
                    <Notifications
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    alertVariant={alertVariant}
                    message={message}
                  />
                  {loading ? <SpinnerGrow /> : null}
                  </Accordion.Body>
                </Accordion.Item>
                {
                  previousBottlesTaken>0?
                  <Accordion.Item eventKey="1">
                  <Accordion.Header>Order Water</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="12">
                        <Form.Label>Bottles Required <span>@{propertyAmount}</span></Form.Label>
                    
                            <Form.Group className="form-group" controlId="product">
                                <InputGroup className="mb-3 input-form-control-increment">
                                  <InputGroup.Text
                                      id="basic-addon1"
                                      onClick={incrementQuantity}
                                  >
                                      +
                                  </InputGroup.Text>
                                  <span className="count-value">{order.quantity.toString()}</span>
                                  <InputGroup.Text
                                      id="basic-addon1"
                                      onClick={decrementQuantity}
                                  >
                                      -
                                  </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                        <Form.Label>Delivery Date</Form.Label>
                        <DatePicker
                            selected={order.uDeliveryDate}
                            className="date-picker"
                            onChange={(date) =>
                            setOrder({ ...order, uDeliveryDate: date })
                            }
                            minDate={isAfter5PM() ? futureDate() : new Date()}
                        />
                        </Col>
                    </Row>
                    <h5 className="total-price text-center">Total Amount to pay <span>{totalAmountPrice}</span></h5>
                    <div className="text-center mt-3">
                        <Button
                            onClick={handleShow}
                            className="btn-pay-later ml-sm"
                            name="PAY_NOW"
                            disabled={payNowEnabled}
                        >
                            Pay Now
                        </Button>
                    </div>
                    {
                      previousBottlesTaken<=0 ?
                      <Alert variant="danger" className="mt-3">
                        Please Pay the bottle Security Deposit amount before placing the order.
                      </Alert>
                      : null
                    }
                    <Notifications
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                      alertVariant={alertVariant}
                      message={message}
                    />
                    {loading ? <SpinnerGrow /> : null}
                  </Accordion.Body>
                </Accordion.Item>
                : null
                }
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Security Deposit Details</Accordion.Header>
                  <Accordion.Body>
                    <h5>Security Deposit paid for <span>{order.bottlesQty}</span> bottles</h5>
                    <Form.Label>If you want more bottles</Form.Label>
                    <Row>
                        <Col md="12" lg="12" sm="12" xs="12">
                            <Form.Group className="form-group" controlId="product">
                                <InputGroup className="mb-3 input-form-control-increment">
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        onClick={incremenBottlesQuantity}
                                    >
                                        +
                                    </InputGroup.Text>
                                    <span className="count-value">{bottlesTaken.toString()}</span>
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        onClick={decremenBootlestQuantity}
                                    >
                                        -
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div class="text-center">
                    <Button onClick={() => handleAdvPayment()} className="btn-pay-later ml-sm">Pay Advance</Button>
                  
                    </div>
                    <Notifications
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                      alertVariant={alertVariant}
                      message={message}
                    />
                    {loading ? <SpinnerGrow /> : null}
                    </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form>

            <h5 className="mt-3">
              If your changes are not reflected Please <a href="#" onClick={() => refreshTokenUser()}>
                Refresh
              </a>
            </h5>
            <Modal show={show} onHide={handleClose} animation={false} centered>
              <Modal.Header closeButton>
                <Modal.Title>Order Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              "An order for <span>{order.quantity.toString()}</span> bottles at Rs. <span>{propertyAmount}</span> each, totaling Rs. <span>{totalAmountPrice}</span>, will be delivered to <span>{order.blockName}, {order.flat}</span>."
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={orderPlaced}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Change Order
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </section>
  );
}
