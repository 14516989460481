import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WaterIcon from '../assets/ion_water.svg'
import Safewater from '../assets/water-lock-sharp.svg'
import Security from '../assets/icon_security.svg'
import PhIcon from '../assets/icon-ph.svg'
export default function Features() {
    return(
        <section className="features">
            <Container>
                <Row>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <Card>
                            <div className="icon">
                                <img src={WaterIcon} alt="WaterIcon" title="WaterIcon" />
                            </div>
                            <div className="content">
                                <p>Aqua Viva undergoes a comprehensive 7-stage filtration process, rigorously removing impurities and contaminants.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <Card>
                            <div className="icon">
                                <img src={Safewater} alt="Safewater" title="Safewater" />
                            </div>
                            <div className="content">
                                <p>Guaranteed safe water due to strict adherence to BIS regulations and comprehensive in-house quality testing at our state-of-the-art laboratory.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <Card>
                            <div className="icon">
                                <img src={Security} alt="Security" title="Security" />
                            </div>
                            <div className="content">
                                <p>Aqua Viva is supercharged with minerals to replenish essential nutrients, enhance hydration, and improve overall well-being.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <Card>
                            <div className="icon">
                                <img src={PhIcon} alt="PhIcon" title="PhIcon" />
                            </div>
                            <div className="content">
                                <p>The optimal pH level in water is meticulously maintained through a precise reverse osmosis process, ensuring a balance that supports hydration and well-being.</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}