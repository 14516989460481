import { SEND_OTP_API_URL, USER_JWT_TOKEN, USER_LOGIN_API_URL, VERIFY_OTP_API_URL,UPDATE_ADDRESS_API_URL, REFRESH_TOKEN_API_URL, REQUEST_ADVANCE_PAYMENT } from "../lib/BaseConstants";

export const SendOtp = (data,propertyInfo) => {
    return new Promise((resolve,reject)=>{
        return fetch(`${SEND_OTP_API_URL}phone/${data}`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              _id:propertyInfo.propertyId,
              name: propertyInfo.propertyName,
              partitionKey: propertyInfo.propertyPartitionKey
            }),
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
              }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}

export const VerifyOtp = (data) => {
    return new Promise((resolve,reject)=>{
        return fetch(`${VERIFY_OTP_API_URL}phone/${data.mobileNumber}/${data.otp}`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
              }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}


export const UpdateAddress = (data) => {
  const token = window.localStorage.getItem(USER_JWT_TOKEN);  
  return new Promise((resolve,reject)=>{
        return fetch(`${UPDATE_ADDRESS_API_URL}`,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'aquavivaJwt': token
            },
            body: JSON.stringify(
                {
                    name:data.name,
                    apartmentBlock:data.apartmentBlock,
                    appartmentFlat:data.appartmentFlat,
                    propertyId: data.propertyId,
                    customerAddressId:data.customerAddressId
                 }
            ),
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
              }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}

export const userLogin = (data) => {
    return new Promise((resolve,reject)=>{
        return fetch(`${USER_LOGIN_API_URL}`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    username:data,
                    "type":"PHONE"
            }),
            credentials: 'include'
        }).then(async response => {
            console.log(response);
            // Extract cookies from response headers
            if (!response.ok) {
                console.log(response);
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
            }

            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}

export const refreshToken = () => {
  const token = window.localStorage.getItem(USER_JWT_TOKEN); 
  return new Promise((resolve,reject)=>{
      return fetch(`${REFRESH_TOKEN_API_URL}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'aquavivaJwt': token
          },
          credentials: 'include'
      }).then(async response => {
          console.log(response);
          // Extract cookies from response headers
          if (!response.ok) {
              console.log(response);
            // If response is not OK, reject the Promise with an error
            return response.json().then(errorData => {
              throw errorData;
            });
          }

          // Parse JSON response
          return response.json();
        })
        .then(jsonData => {
          // Resolve the Promise with JSON data
          resolve(jsonData);
        }).catch((err)=>{
          reject(err)
      })
  })
}


// do the advance payment
export const RequestAdvancePayment = (customerId,propertyId,type,data) => {
  const token = window.localStorage.getItem(USER_JWT_TOKEN);  
  return new Promise((resolve,reject)=>{
        return fetch(`${REQUEST_ADVANCE_PAYMENT}/${customerId}/${propertyId}/${type}`,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'aquavivaJwt': token
            },
            body: JSON.stringify(
                {
                  qty:data.qty
                 }
            ),
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            
              // If response is not OK, reject the Promise with an error
              return response.json().then(errorData => {
                throw errorData;
              });
              }
            // Parse JSON response
            return response.json();
          })
          .then(jsonData => {
            // Resolve the Promise with JSON data
            resolve(jsonData);
          }).catch((err)=>{
            reject(err)
        })
    })
}